<template>
	<div>
		<div class="sc-width">
			<probm :probm="probm"></probm>
		</div>
	</div>
</template>

<script>
	import probm from "@/components/probm/probm"
	import moment from "moment"
	import util from '@/assets/script/util.js'
	export default {
		components: {
			probm
		},
		created() {
			let id = this.$route.query.id
			let index = this.$route.query.index
			this.getNewDeal(index, id)
		},
		data() {
			return {
				probm: {}
			}
		},
		methods: {
			// 查询新闻详情页
			async getNewDeal(index, id) {
				if (index == 4 || index == 5) {
					let url = "Aboutus/news_detail"
					let type = this.$route.query.type
					if(type==1) {
						url = "Aboutus/news_detail2"
					}
					let res = await this.$axios.get(url, {
						params: {
							id
						}
					})
					if (res.code) {
						res.data.inputtime = moment(parseInt(res.data.inputtime * 1000)).format("YYYY-MM-DD")
						if (res.data.subject) {
							res.data.subject = util.setContent(res.data.subject)
						}
						if (res.data.content) {
							res.data.content = util.setContent(res.data.content)
						}
						this.probm = res.data
					}
				} else if (index == 7) {
					let res = await this.$axios.get("Aboutus/news_detail2", {
						params: {
							id
						}
					})
					if (res.code) {
						res.data.inputtime = moment(parseInt(res.data.inputtime * 1000)).format("YYYY-MM-DD")
						res.data.content = util.setContent(res.data.content)
						this.probm = res.data
					}
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .time p:last-child {
		margin-left: 0;
	}
</style>
